import React, {Component} from 'react';

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import repairServiceTypesConstants from "constants/repairServiceTypes";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import VehicleDamageInspectionPointRight
  from "containers/step/vehicleDamageInspectionPoint/VehicleDamageInspectionPointRight";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    vehicleDamageInspectionPoint: Yup.mixed().when(["repairServiceType"], {
        is: (repairServiceType) => repairServiceType === repairServiceTypesConstants.VEHICLE_DAMAGE_INSPECTION_POINT,
        then: Yup.mixed().notOneOf([undefined, null]).required(),
    }),
    businessUnit: Yup.mixed().when(["repairServiceType"], {
        is: (repairServiceType) => repairServiceType === repairServiceTypesConstants.BUSINESS_UNIT,
        then: Yup.mixed().notOneOf([undefined, null]).required(),
    }),
});

// step 32
export default class VehicleDamageInspectionPointStepContainer extends Component {

  left = ({fromSummary, repairServiceType}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`${repairServiceType}.sidebar.title`}/>
        {repairServiceType === repairServiceTypesConstants.VEHICLE_DAMAGE_INSPECTION_POINT
          ? <Text element="div" label={`${repairServiceType}.sidebar.subtitle`} isHtml={true}/>
          : null}
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <RegistryContext.Consumer>
        {registryContext => <VehicleDamageInspectionPointRight registryContext={registryContext} {...props}/>}
      </RegistryContext.Consumer>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
